@use '../../assets/styles/scss/functions' as *;

.menuWrap {
    margin-top: rem(8);

    li {
        button,
        a {
            position: relative;
            display: block;
            width: 100%;
            padding: rem(16);
            font-size: rem(16);
            text-align: left;

            &.btnLogout {
                display: block;
                color: #909090;
            }

            .iconArr {
                position: absolute;
                top: 50%;
                right: rem(18);
                transform: translateY(-50%);
            }

            .appVersion {
                color: #909090;
            }
        }
    }
}
