@use '../../assets/styles/scss/functions' as *;
@use '../../assets/styles/scss/variables' as *;

.modifyProfilePage {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow-y: auto;

    .userProfileArea {
        .profileImgArea {
            margin-top: rem(12);
            text-align: center;

            .uploadImgWrap {
                position: relative;
                display: inline-block;
                vertical-align: top;
                cursor: pointer;

                .blockedClick {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    z-index: 10;

                    .imgArea {
                        position: relative;
                        width: rem(80);
                        height: rem(80);
                        margin: 0 auto;

                        div[class*='MuiAvatar-root'] {
                            width: 100%;
                            height: 100%;
                            margin: 0;
                            padding: 0;
                        }

                        .btnImgUpload {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: none !important;
                            padding: 0;
                            margin: 0;
                            z-index: 5;

                            [class*='MuiButton-startIcon'] {
                                position: absolute;
                                right: 0;
                                bottom: 0;
                                width: rem(29);
                                height: rem(29);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin: 0;
                                background: $c-primary;
                                border-radius: 100%;
                                border: 2px solid #fff;
                                overflow: hidden;
                                z-index: 6;
                                svg {
                                    width: rem(16);
                                }
                            }
                        }
                    }
                }

                .txt {
                    position: absolute;
                    bottom: rem(-38);
                    text-align: center;
                    font-size: rem(16);
                    color: $c-primary;
                }
            }
        }
    }

    .introductionArea {
        margin-top: rem(66);

        .labelList {
            margin-top: rem(24);
            &:first-child {
                margin-top: 0;
            }
        }

        textarea {
            width: 100%;
            padding: rem(12);
            border: 1px solid #ebebeb;
            border-radius: 4px;
            font-size: rem(16);
            resize: none;

            &:focus {
                border-color: #393a3f;
            }

            &::placeholder {
                color: #ababab;
            }
        }
    }

    .btnArea {
        margin-top: rem(16);
    }
}
