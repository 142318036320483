@use '../../../assets/styles/scss/functions' as *;

.blackList {
    li {
        margin-top: rem(4);
        padding: rem(8) rem(16);
        text-align: left;

        &:first-child {
            margin-top: 0;
        }

        button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            text-align: left;
        }

        .textArea {
            max-width: calc(100% - 50px);

            .title {
                display: block;
                width: 100%;
                font-size: rem(16);
                font-weight: 600;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            span {
                display: block;
                padding-top: rem(4);
                font-size: rem(14);
                color: #a7a7a7;
            }
        }
    }
}
