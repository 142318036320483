@use '../../assets/styles/scss/functions' as *;
@use '../../assets/styles/scss/variables' as *;

#searchPage {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .schConArea {
        margin-top: rem(24);
        overflow-y: auto;
    }
}
