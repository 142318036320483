@use '../../../assets/styles/scss/functions' as *;

.searchWayDrawer {
    .searchWayCon {
        padding: rem(28) rem(24);
        ul {
            display: flex;
            justify-content: space-between;
            li {
                text-align: center;
                img {
                    width: rem(32);
                }
                span {
                    display: block;
                    padding-top: rem(8);
                    color: #878b93;
                    font-size: rem(14);
                }
            }
        }
    }
}
