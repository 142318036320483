@use '../../assets/styles/scss/functions' as *;

#Navigation {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px -4px 30px 0px rgba(0, 0, 0, 0.04);

    a,
    button {
        display: block;
        flex: 1 1 0;
        text-align: center;

        span {
            display: none;
        }

        svg {
            vertical-align: middle;
        }
    }
}
