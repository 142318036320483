@use '../../../assets/styles/scss/functions' as *;

.privacyContents {
    padding: rem(24);

    section {
        margin-top: rem(36);
        font-size: rem(16);
        color: #909090;
        line-height: 1.5;

        .title,
        h2 {
            display: block;
            margin-bottom: rem(12);
            font-size: rem(18);
            font-weight: 600;
            color: #383838;
        }

        ol {
            li {
                margin-top: rem(12);
                ol {
                    margin-left: rem(20);
                    margin-bottom: rem(24);
                }
                ul {
                    margin-left: rem(20);
                }
            }
        }

        .indent {
            margin-left: rem(20);
            margin-bottom: rem(24);
        }

        p + ol {
            margin-top: rem(12);
        }

        &:first-child {
            margin-top: 0;
        }
    }
}
