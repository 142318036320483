@use '../../../assets/styles/scss/functions' as *;
@use '../../../assets/styles/scss/variables' as *;

#header {
    flex-shrink: 0;
    height: rem(52);
    padding: 0 rem(16);
    background: #fff;

    .headerWrap {
        position: relative;
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    h1 {
        font-size: rem(18);
        font-weight: 700;

        &.logo {
            font-size: rem(22);
        }
    }

    .btnWrite,
    .btnNoti,
    [class*='rightBtnArea'] {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }

    .btnBack {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        svg {
            vertical-align: middle;
        }
    }

    .btnLogout {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        font-size: rem(14);
        color: #888a8e;
    }

    .btnNoti {
        .notiIcon {
            position: absolute;
            top: -2px;
            right: 0;
            width: rem(6);
            height: rem(6);
            border-radius: 50%;
            background: $c-red;
        }
    }
}
