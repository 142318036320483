.textInputArea {
    position: relative;

    & + .textInputArea {
        margin-top: 0.75rem;
    }

    input {
        padding-right: 1.875rem;
    }

    .btnTxtDelete {
        position: absolute;
        right: 0.75rem;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }

    .Mui-focused {
        background: #000;
        & + .btnTxtDelete {
            display: block;
        }
    }
}
