@use '../../../assets/styles/scss/functions' as *;

#banUserPage {
    height: 100%;
    .banUserList {
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: rem(12);

            &:first-child {
                margin-top: 0;
            }

            .userInfoArea {
                display: flex;
                align-items: center;
                max-width: calc(100% - rem(80));

                .avatarArea {
                    flex-shrink: 0;
                    width: rem(52);
                    height: rem(52);

                    [class*='MuiAvatar-root'] {
                        width: 100% !important;
                        height: 100% !important;
                    }
                }

                .name {
                    margin-left: rem(10);
                    font-size: rem(16);
                    font-weight: 600;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }

            .btnArea {
                flex-shrink: 0;
            }
        }
    }
}
