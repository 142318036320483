@use './assets/styles/scss/reset';
// @use './assets/styles/scss/help';
@use './assets/styles/scss/variables' as *;
@use './assets/styles/scss/functions' as *;
@use './assets/styles/scss/mixin' as *;
@use './assets/styles/scss/modal';

html,
body,
#root,
#contents {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.contentsArea {
    flex: 1;
    height: 100%;
    overflow: hidden;
    // overflow-y: auto;

    .innerWrap {
        padding: rem(20) rem(16);
    }
    .innerWrap02 {
        padding: rem(20) rem(16);
    }

    & > div {
        height: 100%;
        overflow-y: auto;

        &:is(.MuiDrawer-root) {
            height: auto;
            overflow-y: inherit;
        }
    }
}

.section {
    padding: rem(16);
}

.preventPageClickArea {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
}

/* Drawer */
.MuiDrawer-root {
    .drawerTitleArea {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        height: rem(52);
        padding: 0 rem(16);
        .title {
            font-size: rem(16);
            font-weight: 600;
        }
        button {
            margin-right: -4px;
        }
    }
}

/* Modal */
.fullPageModal {
    height: 100%;

    .modalConWrap {
        display: flex;
        flex-direction: column;
        height: 100%;
        background: #fff;

        .modalTitleArea {
            flex-shrink: 0;
            position: relative;
            text-align: center;
            font-size: rem(18);
            font-weight: 600;
            line-height: rem(52);

            .btnClose {
                position: absolute;
                top: 50%;
                right: rem(14);
                transform: translateY(-50%);
            }
        }

        .modalContents {
            height: 100%;
            overflow-y: auto;
            padding: rem(6) rem(16);

            h2 {
                margin-bottom: rem(20);
                font-size: rem(16);
                font-weight: 600;
            }
        }

        .modalBtnArea {
            flex-shrink: 0;
            padding: rem(16);
        }
    }
}

/* ButtonArea */
.fixedBottomBtnArea {
    margin-top: rem(16);

    .termsAreaWrap + & {
        margin-top: rem(30);
    }
}

/* Count */
.totalCountArea {
    margin-bottom: rem(12);
    font-size: rem(14);
    font-weight: 600;

    strong {
        color: $c-primary;
    }
}

/* 약관 동의 */
.agreeCheckList {
    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: rem(18);
        label {
            cursor: pointer;
            span {
                font-weight: 400;
                color: #949494;
                font-size: rem(14);
            }
        }
        .btn_ar {
            position: relative;
            z-index: 10;
        }
    }
}

/* Checkbox Style */
.checkType01 {
    @mixin iconChcek($color: '949494') {
        $color: #{getUtf8Color($color)};
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"%3E%3Cpath d="M17 8.5L11.0525 15.5L7 11.4168" stroke="#{$color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
    }
    @mixin iconChcekCircle($color: 'd3d3d3') {
        $color: #{getUtf8Color($color)};
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"%3E%3Ccircle cx="12" cy="12" r="12" fill="#{$color}"/%3E%3Cpath d="M17 9L11.0525 16L7 11.9168" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
    }

    display: flex;
    align-items: center;
    font-size: rem(14);
    color: #949494;

    .allCheckControl & {
        font-size: rem(16);
        color: #383838;
        cursor: pointer;
    }

    input {
        display: none;
    }

    .icon {
        width: rem(24);
        height: rem(24);
        margin-right: rem(8);
        @include iconChcek;
        background-size: 100%;
    }

    &.circle {
        .icon {
            @include iconChcekCircle;
        }
    }

    input:checked + .icon {
        @include iconChcek($color: '297FFF');
    }
    &.circle {
        input:checked + .icon {
            @include iconChcekCircle($color: '297FFF');
        }
    }
}

/* Switch */
.MuiFormControlLabel-root {
    margin: 0 !important;
}

.customSwitch {
    &.MuiSwitch-root {
        width: 48px;
        height: 30px;
        padding: 0;
        border-radius: 25px;
        overflow: hidden;
    }

    & .MuiSwitch-switchBase {
        padding: 0;
        margin: 0;
        transition-duration: 300ms;

        &.Mui-checked {
            transform: translateX(18px);
            color: #fff;

            & + .MuiSwitch-track {
                background-color: $c-primary;
                opacity: 1;
                border: 0;
            }

            &.Mui-disabled + .MuiSwitch-track {
                opacity: 0.5;
            }
        }
    }

    & .MuiSwitch-thumb {
        box-sizing: border-box;
        width: 26px;
        height: 26px;
        margin-top: 2px;
        margin-left: 2px;
        box-shadow: -4px 4px 4px 4px rgba(0, 0, 0, 0.08);
        transition: border-color 500ms;
    }

    & .MuiSwitch-track {
        border-radius: 25px;
        background-color: #e9e9ea;
        opacity: 1;
        transition: background-color 500ms;
    }
}
