@use '../../../assets/styles/scss/functions' as *;

.setLocationDrawer {
    .MuiPaper-root {
        height: calc(100% - rem(16));
    }
    .drawerWrap {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        .drawerContents {
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;

            #map_div {
                flex-grow: 1;
            }

            .addrArea {
                min-height: rem(43);
                margin-bottom: rem(16);

                .roadAddr {
                    & + .lotAddr {
                        margin-top: rem(2);
                    }
                }

                .lotAddr {
                    display: block;
                    font-size: rem(14);
                    color: #a7a7a7;
                }
                .loading {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: rem(43);
                    color: #777;
                }
            }
        }
    }
}
