@use '../../assets/styles/scss/functions' as *;

#join {
    height: 100%;

    .joinWrap {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: rem(16);

        form {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            width: 100%;

            .fieldWrap {
                padding-top: 30vh;
            }
        }

        .bottomBtnArea {
            width: 100%;
        }

        .joinComplete {
            position: relative;
            top: 40%;
            font-size: rem(18);
            font-weight: 600;
        }
    }
}
