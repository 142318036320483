@use '../../../assets/styles/scss/functions' as *;
@use '../../../assets/styles/scss/variables' as *;

.wideMapList {
    .listLoading {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem(16) 0;
    }
}

.wideMapListItem {
    .mapAreaWrap {
        position: relative;
        height: 79vw;
        max-height: 300px;
        overflow: hidden;

        .mapArea {
            position: relative;
            width: 100%;
            height: 100%;
            background: #ebebeb;
            cursor: pointer;

            .preventDragDiv {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .btnLike {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 36px;
            height: 36px;
            position: absolute;
            bottom: rem(16);
            right: rem(16);
            background: #fff;
            padding: 0 10px;
            font-size: rem(14);
            font-weight: 600;
            color: $c-red;
            border-radius: 40px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
            z-index: 10;

            svg {
                &:last-child {
                    margin-left: -2px;
                    margin-right: -2px;
                }
            }

            .num {
                padding-left: 4px;
            }

            &:active {
                svg {
                    transform: scale(1.2);
                    translate: transform 0.3s ease;
                }
            }
        }
    }

    .postDetailArea {
        padding: rem(12) rem(16) rem(8);

        [class*='userProfileArea'] {
            [class*='userInfoArea'] {
                [class*='userIntroduce'] {
                    color: #65676a;
                }
            }
        }

        .txtAreaWrap {
            display: flex;
            padding-top: rem(12);
        }

        .txtArea {
            .btnMorePost {
                font-size: rem(14);
                color: #737373;
            }
        }

        .btnMoreComment {
            padding-top: rem(12);
            font-size: rem(14);
            font-weight: 600;
            color: #878b93;
        }
    }
}
