@use '../../assets/styles/scss/functions' as *;

.loadingArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 100%;
    height: 100%;
    padding: rem(80) rem(16);
    .loadingText {
        align-items: center;
        font-size: rem(18);
        margin-top: rem(20);
    }
}
