@use '../../../assets/styles/scss/functions' as *;

.pinDetailDrawer {
    .drawerCon {
        padding: rem(16);
    }
    .titleArea {
        position: relative;
        margin-bottom: rem(8);
        .title {
            display: block;
            font-size: rem(18);
            color: #297fff;
        }
        p {
            color: #65676a;
        }
        .btnClose {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    .previewImgWrap {
        width: calc(100% + rem(32));
        margin-left: rem(-16);
        overflow: hidden;

        .previewImgList {
            display: flex;
            overflow-x: auto;
            padding: 0 rem(16);

            &::-webkit-scrollbar {
                display: none;
            }

            li {
                flex-shrink: 0;
                margin-left: rem(4);
                cursor: pointer;

                &:first-child {
                    margin-left: 0;
                }
                img {
                    width: rem(100);
                    height: rem(100);
                    border-radius: 4px;
                    overflow: hidden;
                    object-fit: cover;
                }
            }
        }
    }
    .btnArea {
        margin-top: rem(16);
        button {
            display: flex;
            align-items: center;
            height: rem(36);
            padding: 0 rem(12);
            border-radius: 40px;
            border: 1px solid #ebebeb;
            background: #fff;
            font-size: rem(14);
            color: #222;
            svg {
                margin-right: rem(4);
            }
        }
    }
}
