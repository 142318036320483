@use '../../../assets/styles/scss/functions' as *;

.listBtnArea {
    display: flex;
    justify-content: space-between;
    padding: rem(14) rem(22);

    button {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        font-size: rem(14);
        font-weight: 600;
        color: #65676a;

        svg {
            margin-right: rem(4);
        }

        &:active {
            svg {
                transform: scale(1.2);
                translate: transform 0.3s ease;
            }
        }
    }
}
