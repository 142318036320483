@use '../../assets/styles/scss/functions' as *;
@use '../../assets/styles/scss/variables' as *;

.feedDetailWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .feedDetailCon {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
    }

    .mapAreaWrap {
        position: relative;
        flex-shrink: 0;
        height: 73vw;
        max-height: 300px;
        overflow: hidden;

        .mapArea {
            height: 100%;
            background: #ebebeb;
        }

        .btnLike {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 36px;
            height: 36px;
            position: absolute;
            bottom: rem(16);
            right: rem(16);
            background: #fff;
            padding: 0 10px;
            font-size: rem(14);
            font-weight: 600;
            color: $c-red;
            border-radius: 40px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
            z-index: 10;

            svg {
                &:last-child {
                    margin-left: -2px;
                    margin-right: -2px;
                }
            }

            .num {
                padding-left: 4px;
            }

            &:active {
                svg {
                    transform: scale(1.2);
                    translate: transform 0.3s ease;
                }
            }
        }
    }

    .postDetailArea {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: rem(12) rem(16) rem(8);

        [class*='userProfileArea'] {
            flex-shrink: 0;
            [class*='userInfoArea'] {
                [class*='userIntroduce'] {
                    color: #65676a;
                }
            }
        }

        .txtAreaWrap {
            display: flex;
            flex-shrink: 0;
            padding-top: rem(12);
            white-space: break-spaces;
        }

        .hashTagList {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            margin-top: rem(12);

            span {
                padding: 0 4px;
                border-radius: 4px;
                background: #f6f6f6;
                font-size: rem(14);
                color: #909090;
            }
        }
    }
}
