@use '../../../assets/styles/scss/functions' as *;

.bestIdList {
    display: flex;
    flex-wrap: wrap;

    li {
        display: flex;
        width: 50%;
        margin-top: rem(20);
        color: #3e3e3e;
        cursor: pointer;

        &:nth-child(-n + 2) {
            margin-top: 0;
        }

        .number {
            display: block;
            flex-shrink: 0;
            width: rem(24);
            margin-right: rem(4);
            text-align: center;
            font-weight: 600;
            color: #212121;
            white-space: nowrap;
        }

        .userId {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
