@use '../../../assets/styles/scss/functions' as *;

.photoModal {
    position: relative;
    background: #000;

    .modalWrap {
        position: relative;
        height: 100%;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 115px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.32) 0%, rgba(0, 0, 0, 0) 100%);
            z-index: 10;
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 75px;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
            z-index: 10;
        }
    }

    .sliderWrap {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        .swiper-wrapper {
            align-items: center;
        }
    }

    .btnClose {
        position: absolute;
        top: 8%;
        right: rem(12);
        z-index: 15;
    }

    .photo-pagination {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 4%;
        display: flex;
        justify-content: center;
        z-index: 15;

        .swiper-pagination-bullet {
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background: #fff;
            margin-left: 8px;
            opacity: 0.4;
            cursor: pointer;
            &:first-child {
                margin-left: 0;
            }
            &.swiper-pagination-bullet-active {
                opacity: 1;
            }
        }
    }
}
