@use '../../assets/styles/scss/functions' as *;
@use '../../assets/styles/scss/variables' as *;

.userProfileArea {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;

    .avatarArea {
        flex-shrink: 0;
        cursor: pointer;
    }

    .userInfoArea {
        width: calc(100% - 6rem);
        padding: 0 rem(10);
        font-size: rem(14);

        &.hideBtn {
            width: calc(100% - rem(40));
        }

        .userName {
            display: block;
            font-weight: 600;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;

            b {
                color: $c-primary;
            }
        }
        .userIntroduce {
            display: block;
            color: #a7a7a7;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .btnMore {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: rem(14) !important;
    }
}
