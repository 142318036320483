@use '../../assets/styles/scss/functions' as *;
@use '../../assets/styles/scss/variables' as *;

.notiListWrap {
    li {
        padding: rem(10) rem(14);
        font-size: rem(14);

        .userProfileArea {
            display: flex;
            align-items: center;
            gap: rem(10);
        }

        .time {
            color: $c-gray;
        }

        &.active {
            background: rgba(14, 127, 255, 0.08);
        }
    }

    .progressWrap {
        display: flex;
        justify-content: center;
        margin: 10px 0px;
    }
}
