@use '../../../assets/styles/scss/functions' as *;

#blackListPage {
    [class*='MuiTabPanel-root'] {
        padding: rem(12) 0 !important;
    }
}

.scrollAreaWrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .tabMenu {
        flex-shrink: 0;
    }

    .tabContents {
        height: 100%;
        overflow-y: auto;
    }
}
