@use '../../../assets/styles/scss/functions' as *;
@use '../../../assets/styles/scss/variables' as *;

.uploadImgArea {
    display: flex;
    width: calc(100% + rem(32));
    margin-left: rem(-16);
    padding: rem(12) rem(16) 0 rem(16);
    overflow-x: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    .btnImgUpload {
        position: relative;

        .blockedClick {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            cursor: pointer;
        }
    }

    .btnAddImg {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        padding: 0 !important;
        width: 60px;
        min-width: 60px;
        height: 60px !important;
        border-color: #ebebeb;
        align-items: center;
        justify-content: center;

        & > span {
            margin: 0 !important;
        }

        .num {
            margin-top: -2px;
            margin-bottom: -4px;
            font-size: rem(12);
            b {
                color: $c-primary;
                font-weight: 400;
            }
        }
    }

    .uploadImg {
        display: flex;

        li {
            position: relative;
            width: 60px;
            height: 60px;
            margin-left: rem(8);
            z-index: 100;

            img {
                width: 100%;
                height: 100%;
                border-radius: 4px;
                overflow: hidden;
                object-fit: cover;
            }

            .btnImgDelete {
                position: absolute;
                top: -6px;
                right: -6px;
            }
        }
    }
}
