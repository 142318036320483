@use '../../../assets/styles/scss/functions' as *;

.openLicenseWrap {
    padding: 0 rem(24) rem(24);
    section {
        margin-top: rem(36);
        &:first-child {
            margin-top: rem(20);
        }

        h2 {
            font-size: rem(18);
            font-weight: 600;
            color: #383838;
        }
        p {
            margin-top: rem(12);
            font-size: rem(16);
            color: #909090;

            a {
                text-decoration: none;
                font-weight: 400;
                color: #909090;
            }
        }
    }
}
