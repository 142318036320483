@use '../../../assets/styles/scss/functions' as *;

.searchIdListWrap {
    .userIdList {
        li {
            margin-top: rem(16);
            cursor: pointer;

            &:first-child {
                margin-top: 0;
            }

            [class*='ProfileArea_userInfoArea'] {
                width: calc(100% - rem(40));
                padding-right: 0;
            }
        }
    }
}
