@use '../../assets/styles/scss/functions' as *;

.customPinWrapper {
    background-color: #f1f1f1;

    .selectedPin {
        height: 300px;
        margin: 16px 16px 12px;
        padding: 24px 0px 32px;
        border-radius: 14px;
        background-color: #ffffff;
    }

    .customPinList {
        padding: 12px 16px;

        ul {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;

            li {
                .customPinListItem {
                    background-color: #ffffff;
                    display: inline-flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 20px;
                    width: 100%;
                    height: 100%;
                    aspect-ratio: 1 / 1;
                    padding: 5px;
                    cursor: pointer;

                    .pinInUse {
                        border-radius: 20px;
                        padding: 4px 12px;
                        background-color: #e5efff;
                        display: none;
                        margin-top: 5px;

                        span {
                            font-weight: 600;
                            font-size: rem(14);
                            color: #297fff;
                        }

                        &.isUsed {
                            display: flex;
                        }
                    }

                    &.selected {
                        border: 2px solid #297fff;
                    }

                    & img {
                        width: 50px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}

.selectedPin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .borderless {
        border-radius: 20px;
        padding: 4px 12px;
        background-color: #f6f6f6;
        display: inline-flex;
        margin-bottom: 17px;
        span {
            font-weight: 600;
            font-size: rem(16);
            color: #297fff;
        }
    }

    .selectedMarker {
        & img {
            width: 110px;
        }
    }

    .selectedPinExplan {
        display: flex;
        flex-direction: column;
        text-align: center;

        .selectedPinName {
            display: block;
            font-weight: 600;
            font-size: rem(24);
            color: #222222;
            margin-top: 10px;
        }

        .selectedPinExplan {
            display: block;
            margin-top: 10px;
            font-weight: 400;
            font-size: rem(16);
            color: #646464;
            white-space: pre-line;

            b {
                font-weight: 400;
                color: #297fff;
            }
        }
    }
}

.customPinModal {
    display: flex;
    align-items: center;
    justify-content: center;

    .btnClose {
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 1;
    }

    .modalWrap {
        position: relative;
        width: calc(100% - 32px);
        background: #fff;
        padding: 24px;
        border-radius: 14px;
        overflow: hidden;
    }

    .previewArea {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .btnArea {
        margin-top: 24px;
    }
}
