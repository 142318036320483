@use '../../../assets/styles/scss/functions' as *;
@use '../../../assets/styles/scss/variables' as *;

#reportDrawer {
    width: 100%;
    z-index: 1500 !important;

    .drawerWrap {
        display: flex;
        flex-direction: column;
        max-height: 85dvh;
        overflow: hidden;

        .drawerTitleArea {
            flex-shrink: 0;
        }

        .reportContents {
            padding: rem(8) rem(16);
            overflow-y: auto;

            .reportReasonList {
                font-size: rem(16);
                color: #383838;

                .MuiFormControlLabel-root {
                    margin-bottom: rem(16) !important;
                }

                .MuiRadio-root {
                    padding-left: 0;
                }
            }

            textarea {
                width: 100%;
                resize: none;
                padding: rem(12);
                font-size: rem(16);
                border-radius: 4px;
                border: 1px solid #ebebeb;

                &::placeholder {
                    color: #ababab;
                }

                &:active,
                &:focus {
                    border-color: #222;
                }
            }

            .infoTxt {
                margin-top: rem(20);
                font-size: rem(14);
                text-align: center;
                color: $c-primary;
            }
        }

        .darwerBtnArea {
            padding: rem(4) rem(16) rem(16);
            flex-shrink: 0;
        }
    }
}
