@use './functions' as *;

.fullPageModal {
    z-index: 1500 !important;
}

.layerPop {
    padding: rem(40) 0;
    height: 100%;
    overflow-y: auto;

    .layerHeader {
        padding: 0 rem(24) rem(12);

        .title {
            display: block;
            font-size: rem(24);
            font-weight: 600;
        }
        .btnClose {
            display: none;
        }

        &.scroll {
            position: sticky;
            top: rem(-40);
            left: 0;
            width: 100%;
            height: rem(52);
            padding: rem(14);
            background: #fff;
            text-align: center;
            transition: all 0.3s;

            .title {
                font-size: rem(18);
            }

            .btnClose {
                position: absolute;
                top: 50%;
                right: rem(14);
                display: block;
                transform: translateY(-50%);
            }
        }
    }

    #agreeContents {
        margin-bottom: rem(-40);
    }
}
