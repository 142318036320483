@use '../../../assets/styles/scss/functions' as *;

.searchInputArea {
    position: relative;
    width: 100%;
    flex-shrink: 0;

    input {
        width: 100%;
        padding: rem(12) calc(rem(12) + 30px) rem(12) calc(rem(12) + 24px);
        border-radius: 4px;
        border: 1px solid #ebebeb;
        background: #f9f9f9;
        font-size: rem(16);
        &::placeholder {
            color: #ababab;
        }
    }

    .searchIcon {
        position: absolute;
        top: 50%;
        left: rem(12);
        transform: translateY(-50%);
    }

    .btnTxtDelete {
        position: absolute;
        top: 50%;
        right: calc(rem(12) - 2px);
        transform: translateY(-50%);
        padding: 4px;
        z-index: 10;
    }
}
