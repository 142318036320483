@use '../../../assets/styles/scss/functions' as *;

#reportDetailModal {
    section:not(:first-child) {
        padding-top: rem(16);
        margin-top: rem(16);
        border-top: 1px solid #f6f6f6;
    }

    section {
        dl {
            margin-top: rem(22);
            &:first-child {
                margin-top: 0;
            }
            dt {
                font-size: rem(14);
                color: #7a7a7a;
            }
            dd {
                font-size: rem(16);
                color: #383838;
                white-space: break-spaces;

                .linkButton {
                    font-size: rem(16);
                    color: #383838;
                }
            }
        }

        .radioGroup {
            label {
                display: block;
                margin-top: rem(16);
                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}
