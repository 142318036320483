@use '../../assets/styles/scss/functions' as *;

#login {
    height: 100%;

    .loginWrap {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: rem(80) rem(16);

        .logoArea {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            text-align: center;
            padding-bottom: rem(80);

            .logo {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: rem(48);
                font-weight: 600;

                .logoImg {
                    position: relative;
                    top: rem(6);
                    width: 60px;
                    margin-right: rem(4);
                    margin-left: rem(-8);
                }
            }

            p {
                margin-top: rem(8);
                font-size: rem(18);
                color: #909090;
                letter-spacing: -0.45px;
            }
        }

        .btnWrap {
            display: flex;
            flex-direction: column;
            width: 100%;

            .icon {
                display: inline-block;
                width: rem(24);
                margin-right: rem(4);
                margin-top: -1px;
                vertical-align: middle;
            }

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: rem(50);
                margin-top: rem(12);
                font-size: rem(16);
                font-weight: 400;
                border-radius: 8px;
                &:first-child {
                    margin-top: 0;
                }
                &.apple {
                    background: #000;
                    color: #fff;
                }
                &.kakao {
                    background: #fee500;
                }
            }
        }
    }
}
