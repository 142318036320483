@use '../../assets/styles/scss/functions' as *;
@use '../../assets/styles/scss/variables' as *;

.feedInfo {
    margin-bottom: rem(16);

    .userProfile {
        display: flex;
        align-items: center;

        .profileImgArea {
            position: relative;
            flex-shrink: 0;
            width: rem(64);
            height: rem(64);

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 100%;
                overflow: hidden;
            }

            div[class*='MuiAvatar-root'] {
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;
            }

            .btnModifyProfile {
                position: absolute;
                right: 0;
                bottom: 0;
                padding: rem(4);
                border: 1.5px solid #fff;
                background: $c-primary;
                z-index: 5;

                svg {
                    width: rem(12);
                    height: rem(12);
                }
            }
        }

        .userInfoArea {
            padding-left: rem(12);
            .name {
                display: block;
                font-size: rem(18);
            }
            .intro {
                display: block;
                font-size: rem(14);
                color: #999;
            }
        }
    }

    .feedCountArea {
        display: flex;
        margin-top: rem(18);

        dl {
            position: relative;
            display: flex;
            flex-direction: column-reverse;
            flex: 1;
            align-items: center;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 1px;
                height: 32px;
                background: #f6f6f6;
            }

            &:first-child {
                &::before {
                    display: none;
                }
            }

            &.cusor {
                cursor: pointer;
            }

            dt {
                margin-top: rem(4);
                font-size: rem(14);
                font-weight: 400;
            }

            dd {
                font-weight: 600;
            }
        }
    }

    .btnArea {
        margin-top: rem(24);
        button {
            width: 100%;
        }
    }
}
