@use '../../../assets/styles/scss/functions' as *;

#swipeMap {
    position: relative;
    height: 100%;
    overflow: hidden;

    .centerPos {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: -22px;
        margin-left: 3px;
        text-align: center;
        z-index: 5;

        svg {
            transition: 0.1s ease;
        }

        .txt {
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translateX(-50%);
            background: rgba(11, 32, 68, 0.8);
            padding: rem(8) rem(12);
            border-radius: 4px;
            font-size: rem(14);
            color: #fff;
            white-space: nowrap;
            transition: 0.1s ease;

            &::after {
                position: absolute;
                left: 50%;
                bottom: -4px;
                transform: translateX(-50%);
                content: '';
                width: 0;
                height: 0;
                border-top: 5px solid rgba(11, 32, 68, 0.8);
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
            }
        }

        &.dragOn {
            .txt {
                opacity: 0;
            }
            img {
                opacity: 0.7;
            }
        }
    }

    #map_div {
        height: 100%;
    }

    .btnCurrentPos {
        position: absolute;
        right: rem(6);
        bottom: 16px;
        z-index: 6;
    }

    .mainPinMarker {
        width: 46px;
        height: 58px;
    }
}
