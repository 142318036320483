@use '../../../assets/styles/scss/functions' as *;
@use '../../../assets/styles/scss/variables' as *;

.reportButtonModal {
    display: flex;
    align-items: center;
    justify-content: center;

    .buttonWrap {
        width: 200px;
        background: #fff;
        border-radius: 12px;

        ul {
            li {
                border-top: 1px solid #eee;
                &:first-child {
                    border-top: none;
                }
            }
        }

        button {
            display: block;
            width: 100%;
            padding: rem(18) rem(16);
            font-size: rem(16);
            text-align: center;
            color: $c-primary;
            &.red {
                color: $c-red;
            }
        }
    }
}
