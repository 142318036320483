@use '../../../assets/styles/scss/functions' as *;

.notiSettingWrap {
    margin-top: rem(8);
    .notiSetItemAll {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: rem(16);
        border-bottom: 1px solid #e9e9e9;
        margin-bottom: rem(8);

        .notiTextWrap {
            display: flex;
            flex-direction: column;

            .title {
                font-size: rem(16);
                font-weight: 600;
                line-height: rem(21.76);
            }

            .subtitle {
                font-size: rem(14);
                font-weight: 400;
                color: #a7a7a7;
                line-height: rem(19.04);
            }
        }
    }

    .notiSetItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: rem(8) rem(16);

        .notiTextWrap {
            display: flex;
            flex-direction: column;

            .title {
                font-size: rem(16);
                font-weight: 400;
                line-height: rem(21.76);
            }

            .subtitle {
                font-size: rem(14);
                font-weight: 400;
                color: #a7a7a7;
                line-height: rem(19.04);
            }
        }
    }
}
